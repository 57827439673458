a {
    color: white;
}

.app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100vw;
    background: url('../src/images/background.png');
    background-size: cover;
    background-position: center;
    flex-direction: column;
}

h1 {
    color: #FFF;
    font-family: 'Open Sans';
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    max-width: 90%;
    margin: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

p {
    color: #FFF;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    text-align: left;
}

#content > div.container > p {
    color: #FFF;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    text-align: left;
}

red {
    color: red;
}

.container {
    border-radius: 25px;
    background: #151616;
    width: 560px;
    height: 160px;
    display: flex;
    padding: 15px 30px;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

div.content > div > input[type=text] {
    width: calc(100% - 40px);
    border: 0;
    height: 31px;
    flex-shrink: 0;
    border-radius: 26px;
    background: #3B3B3B;
    color: white;
}

input {
    padding-left: 20px;
    padding-right: 20px;
}

div > div:nth-child(3) > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div.content > div > div > div {
    display: flex;
    justify-content: flex-end;
}

div > div:nth-child(3) > div > button {
    border-radius: 44px;
    background: #D9D9D9;
    width: 100px;
    height: 33px;
    flex-shrink: 0;
    border: 0;
    color: #000;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background {
    z-index: -2;
}

div.background > img:nth-child(1) {
    position: absolute;
    top: -50vh;
    left: -20vw;
}

div.background > img:nth-child(2) {
    position: absolute;
    bottom: -50vh;
    right: -20vw;
}

div.background > img:nth-child(3) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.use {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
    align-items: center;
}

.use img {
    max-width: 70%;
    width: 600px;
}

.gray {
    padding: 5px 15px;
    background: #3B3B3B;
    border-radius: 10px;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all;
}

div > div.container > div > div {
    display: flex;
}

div > div.container > div > div > svg {
    width: 40px;
    margin-right: 40px;
}

.red {
    gap: 10px;
    text-decoration: none;
    border-radius: 10px;
    padding: 5px 15px;
    color: white;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.red img {
    height: 90%;
}

.error {
    position: absolute;
    margin-left: 500px;
    background: url('./images/error.png');
    width: 177px;
    height: 54.24px;
    background-size: contain;
    margin-bottom: 40px;
    background-repeat: no-repeat;
}

.error > p {
    font-size: 14px;
    color: rgb(255, 255, 255, 100%);
    margin-top: 15px;
    margin-left: 20px;
}

/* sub-content */

.sub_content {
    max-width: 80%;
    text-align: left;
    padding: 20px;
    background-color: #1D1D1D;
    border-radius: 25px;
    margin-bottom: 100px;
}

.sub_content > div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
}

.sub_content > div:nth-child(1) > .img {
    width: 70px;
    height: 70px;
    background-image: url('../src/images/png1.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.sub_content p {
    font-size: 14px;
    font-weight: 400;
}

.sub_h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 24px;
    width: auto;
    font-weight: 700;
}

.sub_h2 {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: left;
    font-size: 18px;
    width: auto;
    color: white;
}

#converter > div:nth-child(5) > h2 {
    color: rgba(255, 255, 255, 0.66);
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}

.content_button {
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
}

.content_button > a {
    color: white;
    background: red;
    text-decoration: none;
    padding: 20px 40px;
    border-radius: 25px;
    font-weight: 400;
    transition: 0.2s all;
}

.content_button > a:hover {
    color: white;
    background: rgba(255, 0, 0, 0.71);
    text-decoration: none;
    padding: 20px 40px;
    border-radius: 25px;
    font-weight: 400;
}

.h1_heading {
    display: inline;
}

.gray:hover {
    background: rgba(59, 59, 59, 0.76);

}

.header {
    position: absolute;
    right: 20px;
    top: 20px;
}

@media screen and (max-width: 700px) {
    .h1_heading {
        display: inline-flex;
    }

    .error {
        margin-top: -10px;
        right: 5%;
    }

    div.content > div {
        width: 300px;
    }

    div.background > img:nth-child(1),
    div.background > img:nth-child(2) {
        display: none;
    }

    #root > div > div.background > img:nth-child(3) {
        width: 300px;
    }


}

@media screen and (max-width: 400px) {
    div > div.container {
        width: 250px;
    }

    h1 {
        font-size: 22px;
    }
}