.site_map {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.content_map {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.content_map p {
    font-weight: 400;
    font-size: 22px;
}